import { combineReducers } from 'redux'
import cart, * as fromCart from './cart'
import products, * as fromProducts from './products'
import auth from "./login";
import orders from "./order";
import categories from "./categories";




export default combineReducers({
    cart,
    products,
    auth,
    orders,
    categories
})

const getAddedIds = state => fromCart.getAddedIds(state.cart)
const getQuantity = (state, id) => fromCart.getQuantity(state.cart, id)
const getProduct = (state, id) => fromProducts.getProduct(state.products, id)

export const getTotal = state => {
    if (state.products && state.products.byId && Object.keys(state.products.byId).length === 0) {
        // Products are not available, return an initial total or handle differently
        return 0; // Initial total or any default value
    }
    return getAddedIds(state)
        .reduce((total, id) =>
                total + getProduct(state, id).price*(1-getProduct(state, id).discount) * getQuantity(state, id),
            0
        )
        .toFixed(2)
}

export const getTotalWithDiscountCode = state => {

    const discount = state.cart.discount || 0;
    console.log("discount in reducer", discount)

    if (state.products && state.products.byId && Object.keys(state.products.byId).length === 0) {
        // Products are not available, return an initial total or handle differently
        return 0; // Initial total or any default value
    }
    const totalWithoutDiscountCode =  getAddedIds(state)
        .reduce((total, id) =>
                total + getProduct(state, id).price*(1-getProduct(state, id).discount) * getQuantity(state, id),
            0
        )
        .toFixed(2)
    return totalWithoutDiscountCode * (1 - discount)
};

export const getTotalFullPrice = state => {
    if (state.products && state.products.byId && Object.keys(state.products.byId).length === 0) {
        // Products are not available, return an initial total or handle differently
        return 0; // Initial total or any default value
    }
    return getAddedIds(state)
        .reduce((total, id) =>
                total + getProduct(state, id).price * getQuantity(state, id),
            0
        )
        .toFixed(2)
}

// export const getTotal = state => {
//     // console.log(state)
//     // console.log('addedIds:', state.products.byId )
//     // console.log('addedIds:', addedIds);
//     const addedIds = getAddedIds(state);
//     // console.log(state.products.byId !=)
//     if (state.products && state.products.byId && Object.keys(state.products.byId).length === 0) {
//         // Products are not available, return an initial total or handle differently
//         return 0; // Initial total or any default value
//     }
//
//     const total = addedIds.reduce((total, id) => {
//         const product = getProduct(state, id);
//         console.log(`Product for id ${id}:`, product);
//
//         const quantity = getQuantity(state, id);
//         console.log(`Quantity for id ${id}:`, quantity);
//
//         return total + product.price * quantity;
//     }, 0);
//     console.log('Total before formatting:', total);
//
//     const formattedTotal = total.toFixed(2);
//     console.log('Formatted Total:', formattedTotal);
//
//     return formattedTotal;
// };


// export const getCartProducts = state =>
//     getAddedIds(state).map(id => ({
//         ...getProduct(state, id),
//         quantity: getQuantity(state, id)
//     }))

export const getCartProducts = state =>
    getAddedIds(state).map(id => ({
        ...getProduct(state, id),
        quantity: getQuantity(state, id)
    }))
