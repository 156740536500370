// import 'bootstrap/dist/css/bootstrap.min.css';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import './Navbar.css'
import "../fonts/fonts.css"
import logo from "../images/logo_izrezani.png"
import {getTotalQuantity} from "../reducers/cart";
import {connect} from "react-redux";
import { logout} from "../actions";

import React, {useEffect, useState} from 'react';
import './Navbar.css';


function Navbar({ cartItemCount }) {
    let navigate = useNavigate();
    const isAuthenticated = localStorage.getItem("auth") === "true"
    const location = useLocation();


    const [scrolled, setScrolled] = useState(false);
    const [navbarOpen, setNavbarOpen] = useState(false);
    // const [dropdownOpen, setDropdownOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [showBanner, setShowBanner] = useState(location.pathname.startsWith('/shop') || location.pathname.startsWith('/cart'));
    const [showLogo, setShowLogo] = useState(false);


    useEffect(() => {
        if (!navbarOpen) {
            const timeout = setTimeout(() => {
                setShowLogo(true);
            }, 500); // Adjust this timeout based on your animation duration

            return () => clearTimeout(timeout);
        } else {
            setShowLogo(false);
        }
    }, [navbarOpen]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const [bannerTextIndex, setBannerTextIndex] = useState(0);
    const bannerTexts = [
        'Besplatna dostava za narudžbe unutar Zagreba',
        'Besplatna dostava iznad 70€ za sve narudžbe',
        'Narudžbe zaprimljene do 16 sati bit će poslane istog dana',
        // Dodajte više tekstova za banner po potrebi
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setBannerTextIndex((prevIndex) => (prevIndex + 1) % bannerTexts.length);
        }, 5000); // Change text every 5 seconds (5000 milliseconds)

        return () => clearInterval(interval);
    }, [bannerTexts.length]);

    useEffect(() => {
        setShowBanner(location.pathname.startsWith('/shop') || location.pathname.startsWith('/cart'));
    }, [location.pathname]);

    const handleToggle = () => {
        setNavbarOpen(!navbarOpen);
    };

    const handleScroll = () => {
        if (window.scrollY > 200) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleLogout = () => {
        logout();
        localStorage.removeItem("jwtToken")
        localStorage.removeItem("auth")
        navigate('/', { replace: true }); // Navigate to the home page after logout
        window.location.reload(); // Refresh the page
    };

    const handleNavLinkClick = () => {
        const btn2 = document.getElementById('navbar-toggler');
        btn2.click();
        }



    return (
        <>
            {showBanner &&
                <div className="top-banner">
                    <div className="container-banner">
                        <div className="banner-text">{bannerTexts[bannerTextIndex]}</div>
                    </div>
                </div>
            }
            <div className={`navbar${scrolled ? ' scrolled' : ''}`} id="navbar"  style={{ zIndex: "100", marginTop: showBanner ? '1.8rem' : '', backgroundColor: showBanner ? "white": "" }}>
                {/*{navbarOpen && <div className="navbar-bg-big" style={{ marginTop: showBanner ? '3rem' : ''}}></div>}*/}
                {navbarOpen && !(window.location.pathname === "/" && !scrolled) && (
                    <div className="navbar-bg-big" style={{ marginTop: showBanner ? '3rem' : ''}}></div>
                )}
                <nav className={`navbar-bg${scrolled ? ' scrolled' : ''} navbar-expand-lg navbar-light font-pro grandparent`} >
                    {(showLogo || windowWidth > 991) && (
                        <div className="logo-div">
                            <img src={logo} className="logo-img" alt="Logo" />
                        </div>
                    )}
                    <button className="navbar-toggler" type="button" id="navbar-toggler" onClick={handleToggle} data-toggle="collapse" data-target="#collapsibleNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-center centered parent font-navbar" id="collapsibleNavbar">
                        <ul className="navbar-nav center">
                            <li className="nav-item active nav-item-not-dropdown">
                                <Link className="nav-link" id="nav-link" to="/" onClick={handleNavLinkClick}>Početna <span className="sr-only"></span></Link>
                            </li>
                            <li className="nav-item nav-item-not-dropdown">
                                <Link className="nav-link" id="nav-link" to="/tretmani" onClick={handleNavLinkClick}>Tretmani</Link>
                            </li>
                            <li className="nav-item nav-item-not-dropdown">
                                <Link className="nav-link" id="nav-link" to="/tretmani/cjenik" onClick={handleNavLinkClick}>Cjenik</Link>
                            </li>

                            {/*<li className="nav-item">*/}
                            {/*    /!*<div className="dropdown">*!/*/}
                            {/*    /!*    <button*!/*/}
                            {/*    /!*        className="btn-custom"*!/*/}
                            {/*    /!*        type="button"*!/*/}
                            {/*    /!*        id="dropdownMenuButton"*!/*/}
                            {/*    /!*        data-mdb-toggle="dropdown"*!/*/}
                            {/*    /!*        aria-expanded="false"*!/*/}
                            {/*    /!*    >*!/*/}
                            {/*    /!*        <Link className="nav-link" id="nav-link" to="/tretmani">Salon</Link>*!/*/}
                            {/*    /!*    </button>*!/*/}
                            {/*    /!*    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">*!/*/}
                            {/*    /!*        <li><Link className="dropdown-item" id="nav-link" to="/tretmani"  onClick={handleNavLinkClick}>Dostupni tretmani</Link></li>*!/*/}
                            {/*    /!*        <li><Link className="dropdown-item" id="nav-link" to="/tretmani/cjenik"  onClick={handleNavLinkClick}>Cjenik</Link></li>*!/*/}
                            {/*    /!*    </ul>*!/*/}
                            {/*    /!*</div>*!/*/}
                            {/*    <div className="dropdown" onMouseEnter={() => setDropdownOpen(true)} onMouseLeave={() => setDropdownOpen(false)}>*/}
                            {/*        <button className="btn-custom" type="button" id="dropdownMenuButton">*/}
                            {/*            <Link className="nav-link" id="nav-link" to="/tretmani" onClick={handleNavLinkClick}>Salon</Link>*/}
                            {/*        </button>*/}
                            {/*        {dropdownOpen && (*/}
                            {/*            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">*/}
                            {/*                <li><Link className="dropdown-item" id="nav-link" to="/tretmani"  onClick={handleNavLinkClick}>Dostupni tretmani</Link></li>*/}
                            {/*                <li><Link className="dropdown-item" id="nav-link" to="/tretmani/cjenik"  onClick={handleNavLinkClick}>Cjenik</Link></li>*/}
                            {/*            </ul>*/}
                            {/*        )}*/}
                            {/*    </div>*/}
                            {/*</li>*/}
                            <li className="nav-item nav-item-not-dropdown">
                                <Link className="nav-link" id="nav-link" to="/shop" onClick={handleNavLinkClick}>Shop</Link>
                            </li>
                            {isAuthenticated &&
                                <li className="nav-item nav-item-not-dropdown">
                                    <Link className="nav-link" id="nav-link" to="/narudzbe"  onClick={handleNavLinkClick}>Narudžbe</Link>
                                </li>
                            }
                            <li className="nav-item nav-item-not-dropdown">
                                <Link className="nav-link" id="nav-link"  to="/kontakt" onClick={handleNavLinkClick}>Kontakt</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="navbar-text order-lg-last right-content">
                        <div style={{ display: "flex", alignItems: "center"}} className="d-lg-flex košarica">
                            <Link className="nav-link right-content right" id="nav-link" to="/cart">Košarica</Link>
                            <span id="nav-link">{"(" + cartItemCount + ")"}</span>
                            {isAuthenticated && (
                                <button className=" btn-link nav-link" id="nav-link" onClick={handleLogout} style={{ marginLeft: "2em" }}>
                                    Logout
                                </button>
                            )}
                        </div>
                    </div>
                </nav>
            </div>
        </>
        );
    }


const mapStateToProps = state => {
    const cartItemCount = getTotalQuantity(state, /* productId */);
    return {
        cartItemCount,
    };
};

const mapDispatchToProps = {
    logout
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Navbar);
