import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Product.css';

const Product = ({ productId, brandName, image, price, quantity, productName, discount, specialPrice, onIncrement, onDecrement, onDeleteAll, onCheckoutClicked }) => {
    const [quantity_, setQuantity] = useState(quantity); // Initial quantity value
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const updateQuantityDisplay = (newQuantity) => {
        setQuantity(isNaN(newQuantity) ? 0 : newQuantity);
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleIncrement = () => {
        const success = onIncrement(productId); // Call onIncrement and store the return value
        if (success) {
            updateQuantityDisplay(quantity_ + 1); // Update quantity display only if onIncrement was successful
        } else {
            console.log("Increment failed or product not found in cart.");
            // Optionally, you can display a message to the user indicating the failure
        }
    };


    const deleteAll = () => {
        onDeleteAll(productId);
        updateQuantityDisplay(quantity_ + 1);
    };

    const handleDecrement = () => {
        onDecrement(productId);
        updateQuantityDisplay(quantity_ - 1);
    };

    console.log(discount !== 0)
    return (
        <tr>
            {windowWidth >= 800 ? (
                <>
                    <th scope="row">
                        <div className="d-flex align-items-center">
                            <img src={`/images/products/${image}`} fluid className="rounded-3" style={{ width: '120px' }} alt="Book" />
                            <div className="flex-column ms-4">
                                <p className="mb-2 font-pro font-weight-normal font-size-cart">{brandName + ' ' + productName}</p>
                            </div>
                        </div>
                    </th>
                    <td className="align-middle">
                        <div className="d-flex flex-row align-items-center font-pro " style={{ justifyContent: "center"}}>
                            <div style={{padding: "2px"}}>
                                <button className="px-2 btn-cart" color="black" style={{ marginBottom: '0' }} onClick={handleDecrement}>
                                    <span style={{ fontWeight: "bold" }} className="">
                                        -
                                    </span>
                                </button>
                                <span style={{ margin: '0 5px' }}>{quantity_}</span>
                                <button className="px-2 btn-cart font-pro" color="grey" style={{ marginBottom: '0' }} onClick={handleIncrement}>
                                    <span style={{ fontSize: 'large' }} className="">
                                        +
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div>
                            <button
                                className="px-1 btn-cart font-tiskani"
                                color="grey"
                                style={{ marginBottom: '0', fontSize: '0.8em', textDecoration: 'underline' }}
                                onClick={deleteAll}
                            >
                                Ukloni
                            </button>
                        </div>
                    </td>
                    <td className="align-middle">
                        {discount !== 0 &&
                            <button
                                className="mb-2 text-btn font-pro font-size-cart"
                                style={{marginRight: "1rem"}}
                            >{parseFloat(specialPrice*quantity_).toFixed(2)+ "€"}</button>
                        }
                        {discount !== 0 &&
                            <button
                                className="mb-2 text-btn strike-through-text dark-grey-text font-pro font-size-cart"
                                // style={{margin: "0.4rem"}}
                            >{parseFloat(price*quantity_).toFixed(2)+ "€"}
                            </button>
                        }
                        {discount === 0 &&
                            <button
                                className="mb-2 font-pro text-btn font-pro font-size-cart">{parseFloat(price*quantity_).toFixed(2)+ "€"}</button>
                        }
                        {/*<p className="mb-0 font-weight-normal font-pro">{price * quantity_}€</p>*/}
                    </td>
                </>
            ) : (
                <>
                    <td>
                        <div>
                            <img src={`/images/products/${image}`} fluid className="rounded-3" style={{ width: '120px' }} alt="Book" />
                        </div>
                        <div>
                            {/*<p className="mb-2 font-pro font-weight-normal font-size-cart">{brandName + ' ' + productName}</p>*/}
                        </div>
                    </td>
                    <td>
                        <div className="d-flex flex-column">
                            <div className="d-flex flex-row" style={{justifyContent: "space-between"}}>
                            <p className="mb-2 font-weight-normal font-pro font-size-cart product-name-container">{brandName + " " + productName}</p>
                                <div>
                            {discount !== 0 &&
                                <button
                                    className="mb-2 text-btn strike-through-text dark-grey-text font-pro font-size-cart price-btn-text-top"
                                    style={{marginRight: "0.5rem"}}
                                >{price*quantity_ + "€"}
                                </button>
                            }
                            {discount !== 0 &&
                                <button
                                    className="mb-2 text-btn font-pro font-size-cart price-btn-text-top"
                                    // style={{marginRight: "1rem"}}
                                >{specialPrice*quantity_ + "€"}</button>
                            }
                                </div>

                            {discount === 0 &&
                                <button
                                    className="mb-2 font-pro text-btn font-pro font-size-cart price-btn-text-top">{price*quantity_ + "€"}</button>
                            }
                            </div>
                            <div className="d-flex flex-row align-items-center">
                                <div className="kolicina-div">
                                    <button className="px-2 btn-cart" color="black" style={{ marginBottom: '0' }} onClick={handleDecrement}>
                                        <span style={{ fontWeight: "bold" }} className="">
                                            -
                                        </span>
                                    </button>
                                    <span style={{ margin: '0 5px' }}>{quantity_}</span>
                                    <button className="px-2 btn-cart" color="grey" style={{ marginBottom: '0' }} onClick={handleIncrement}>
                                        <span style={{ fontSize: 'large' }} className="">
                                            +
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div>
                                <button
                                    className="px-1 btn-cart font-tiskani"
                                    color="grey"
                                    style={{ marginBottom: '0', fontSize: '0.8em', textDecoration: 'underline' }}
                                    onClick={deleteAll}
                                >
                                    Ukloni
                                </button>
                            </div>
                        </div>
                    </td>
                </>
            )}
        </tr>
    );
};

Product.propTypes = {
    productId: PropTypes.number,
    image: PropTypes.string,
    price: PropTypes.number,
    inventory: PropTypes.number,
    productName: PropTypes.string,
    brandName: PropTypes.string,
    quantity: PropTypes.number,
    discount: PropTypes.number,
    specialPrice: PropTypes.number,

};

export default Product;
