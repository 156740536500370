import React from "react";

const WebshopUnavailable = () => {
    return (
        <div className="centered-container color padding" style={{paddingTop: "10rem"}}>
            <div className="outer-div">
                <h1 className="font-tiskani veliki" style={{padding:"2rem"}}>Webshop nije dostupan</h1>
                <p className="font-tiskani left-aligned-text">
                    Our webshop is currently under construction. Please check back later!
                </p>
                {/*<button id="button-pay">Notify Me</button>*/}
            </div>
        </div>
    );
};

export default WebshopUnavailable;
