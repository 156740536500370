export const ADD_TO_CART = 'ADD_TO_CART'
export const ADD_TO_CART_QUANTITY = 'ADD_TO_CART_QUANTITY'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const REMOVE_ALL_FROM_CART = 'REMOVE_ALL_FROM_CART'
export const CHECKOUT_REQUEST = 'CHECKOUT_REQUEST'
export const CHECKOUT_COMPLETED = 'CHECKOUT_COMPLETED'
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS'
export const CHECKOUT_FAILURE = 'CHECKOUT_FAILURE'
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS'
export const RECEIVE_CATEGORIES = 'RECEIVE_CATEGORIES'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT';
export const PRODUCT_UPDATED = 'PRODUCT_UPDATED';
export const ORDER_SENT = 'ORDER_SENT';
export const ORDER_NOT_SENT = 'ORDER_NOT_SENT';
export const ORDER_PAID = 'ORDER_PAID';
export const ORDER_NOT_PAID = 'ORDER_NOT_PAID';
export const RECEIVE_ORDERS = 'RECEIVE_ORDERS';
export const ORDER_DELETED = 'ORDER_DELETED';
export const APPLY_DISCOUNT = 'APPLY_DISCOUNT';
// export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST';
// export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
// export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';
