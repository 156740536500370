import {
    ADD_TO_CART,
    REMOVE_FROM_CART, ADD_TO_CART_QUANTITY, REMOVE_ALL_FROM_CART, CHECKOUT_COMPLETED, APPLY_DISCOUNT
} from '../constants/ActionTypes'


// Load cart state from local storage
// const persistedCartState = localStorage.getItem('ad');
// const initialState = persistedCartState ? JSON.parse(persistedCartState) : {};


const initialState = {
    addedIds: [],
    quantityById: {},
    discount: 0,
    discountCode: ""
}

const loadCartStateFromLocalStorage = () => {
    try {
        const persistedCartState = localStorage.getItem('cart');
        if (persistedCartState) {
            return JSON.parse(persistedCartState);
        }
    } catch (error) {
        console.error('Error loading cart state from local storage:', error);
    }
    return initialState;
};

const saveCartStateToLocalStorage = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('cart', serializedState);
    } catch (error) {
        console.error('Error saving cart state to local storage:', error);
    }
};

const subscribeToStoreChanges = (store) => {
    let currentState = store.getState();
    store.subscribe(() => {
        const nextState = store.getState();
        if (nextState !== currentState) {
            saveCartStateToLocalStorage(nextState.cart);
            currentState = nextState;
        }
    });
};


const quantityById = (state = initialState.quantityById, action) => {
    const { productId } = action
    switch (action.type) {
        case ADD_TO_CART:
            return { ...state,
                [productId]: (state[productId] || 0) + 1
            }
        case ADD_TO_CART_QUANTITY:
            const { quantity } = action
            return { ...state,
                [productId]: (state[productId] || 0) + quantity
            }
        case REMOVE_FROM_CART:
            if (state[action.productId] > 1) {
                return {
                    ...state,
                    [action.productId]: state[action.productId] - 1
                }
            } else {
                const newState = { ...state };
                delete newState[action.productId];
                return newState;
            }
        case REMOVE_ALL_FROM_CART:
            if (state[action.productId] > 1) {
                return {
                    ...state,
                    [action.productId]: 0
                }
            } else {
                const newState = { ...state };
                delete newState[action.productId];
                return newState;
            }

        default:
            return state
    }
}

export const getQuantity = (state, productId) =>
    state.quantityById[productId] || 0

// export const getAddedIds = state => state.addedIds

export const getAddedIds = state => {
    return Object.keys(state.quantityById).filter(productId => state.quantityById[productId] !== 0);
};

export const getTotalQuantity = state => {
    const { quantityById } = state.cart;
    return Object.values(quantityById).reduce((total, quantity) => total + quantity, 0);
};

export const initializeCartPersistence = (store) => {
    subscribeToStoreChanges(store);
};


const cart = (state = loadCartStateFromLocalStorage(), action) => {
    console.log('Current State:', state);
    console.log('Action Type:', action.type);

    switch (action.type) {
        // case CHECKOUT_REQUEST:
        //     return initialState
        case CHECKOUT_COMPLETED:
            return initialState
        // case CHECKOUT_FAILURE:
        //     return action.cart
        case APPLY_DISCOUNT:
            const { discount } = action.payload;
            const { code } = action.payload;

            return {
                ...state,
                discount, // Set the discount to the state
                code
            };

        default:
            return {
                // addedIds: addedIds(state.addedIds, action),
                quantityById: quantityById(state.quantityById, action)
            }
    }
}

export default cart
