import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {getAllProducts, getDiscountCode, validateAndApplyDiscount} from '../actions'; // Import getAllProducts action creator
import { addToCart, checkout, removeFromCart, removeAllFromCart } from '../actions';
import {getTotal, getCartProducts, getTotalFullPrice, getTotalWithDiscountCode} from '../reducers';
import Cart from '../pages/shop/cart/Cart';

const CartContainer = ({ products, discount, code, total, totalWithoutDiscount, totalWithDiscountCode, getAllProducts, checkout, addToCart, removeFromCart, removeAllFromCart, validateAndApplyDiscount, getDiscountCode }) => {
    useEffect(() => {
        // Dispatch the getAllProducts action when the component mounts
        getAllProducts();
    }, [getAllProducts]);

    if (!products || !total) {
        return <div>Loading...</div>; // Render loading indicator if products or total are not available
    }

    return (
        <Cart
            products={products}
            total={total}
            discount={discount}
            code={code}
            totalWithoutDiscount={totalWithoutDiscount}
            totalWithDiscountCode={totalWithDiscountCode}
            getAllProducts={getAllProducts}
            onCheckout={(form) => checkout(form)}
            onIncrement={(productId) => addToCart(productId)}
            onDecrement={(productId) => removeFromCart(productId)}
            onDeleteAll={(productId) => removeAllFromCart(productId)}
            onDiscountCode={(discountCode) => validateAndApplyDiscount(discountCode)}
            getDiscountCode={(discountCode) => getDiscountCode(discountCode)}
        />
    );
};

CartContainer.propTypes = {
    products: PropTypes.arrayOf(PropTypes.shape({
        productId: PropTypes.number.isRequired,
        productName: PropTypes.string.isRequired,
        brandName: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        specialPrice: PropTypes.number.isRequired,
        discount: PropTypes.number.isRequired,
        quantity: PropTypes.number.isRequired,
        image: PropTypes.string.isRequired
    })),
    discount: PropTypes.string,
    code: PropTypes.string,
    total: PropTypes.string,
    totalWithoutDiscount: PropTypes.string,
    totalWithDiscountCode: PropTypes.string,
    getAllProducts: PropTypes.func.isRequired, // Add getAllProducts to propTypes
    checkout: PropTypes.func.isRequired,
    addToCart: PropTypes.func.isRequired,
    removeFromCart: PropTypes.func.isRequired,
    removeAllFromCart: PropTypes.func.isRequired,
    validateAndApplyDiscount: PropTypes.func.isRequired,
    getDiscountCode: PropTypes.func.isRequired,

};

const mapStateToProps = (state) => ({
    products: getCartProducts(state),
    totalWithDiscountCode: getTotalWithDiscountCode(state),
    total: getTotal(state),
    totalWithoutDiscount: getTotalFullPrice(state),
    discount: state.cart.discount, // Access discount from the cart state
    code: state.cart.code // Access discount code from the cart state
});

const mapDispatchToProps = {
    getAllProducts, // Add getAllProducts to mapDispatchToProps
    checkout,
    addToCart,
    removeFromCart,
    removeAllFromCart,
    validateAndApplyDiscount,
    getDiscountCode
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CartContainer);
