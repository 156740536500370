import api from '../api/api'
import * as types from '../constants/ActionTypes'
import {
    LOGIN,
    LOGOUT,
    ORDER_DELETED, ORDER_NOT_PAID,
    ORDER_NOT_SENT,
    ORDER_PAID,
    ORDER_SENT,
    PRODUCT_UPDATED,
} from "../constants/ActionTypes";

const receiveProducts = products => ({
    type: types.RECEIVE_PRODUCTS,
    products
})

export const getAllProducts = () => dispatch => {
    api.getProducts(products => {
        dispatch(receiveProducts(products))
    })
}

const receiveCategories = categories => ({
    type: types.RECEIVE_CATEGORIES,
    categories
})

export const getAllCategories = () => dispatch => {
    api.getCategories(categories => {
        dispatch(receiveCategories(categories))
    })
}

const addToCartUnsafe = productId => ({
    type: types.ADD_TO_CART,
    productId
})

const addToCartQuantityUnsafe = (productId, quantity) => ({
    type: types.ADD_TO_CART_QUANTITY,
    productId,
    quantity
})

const removeFromCartUnsafe = productId => ({
    type: types.REMOVE_FROM_CART,
    productId
})

const removeAllFromCartUnsafe = productId => ({
    type: types.REMOVE_ALL_FROM_CART,
    productId
})

const createOrder = (order) => ({
    type: types.CHECKOUT_REQUEST,
    order
})

const confirmOrderAction = (msg) => ({
    type: types.CHECKOUT_COMPLETED,
    msg
})

const cancelOrderAction = (msg) => ({
    type: types.CHECKOUT_FAILURE,
    msg
})

export const loginAction = (token) => ({
    type: LOGIN,
    payload: token,
});

export const updateProductAction = (updatedProduct) => ({
    type: PRODUCT_UPDATED,
    updatedProduct});

export const logoutAction = () => ({
    type: LOGOUT,
});



export const orderSentAction = (order) => {
    return {
        type: ORDER_SENT,
        payload: order
    };
};

export const orderPaidAction = (order) => {
    return {
        type: ORDER_PAID,
        payload: order
    };
};

export const orderNotPaidAction = (order) => {
    return {
        type: ORDER_NOT_PAID,
        payload: order
    };
};

export const deleteOrderAction = (order) => {
    return {
        type: ORDER_DELETED,
        payload: order
    };
};

export const orderNotSentAction = (order) => {
    return {
        type: ORDER_NOT_SENT,
        payload: order
    };
};


const receiveOrders = orders => ({
    type: types.RECEIVE_ORDERS,
    orders
})


export const applyDiscountAction = (discountCode) => ({
    type: types.APPLY_DISCOUNT,
    payload: { discount: discountCode.discount, code: discountCode.code},
});

//
// export const validateAndApplyDiscount = (discountCode) => (dispatch) => {
//     return new Promise((resolve, reject) => {
//         api.checkDiscountCodeForAll(discountCode, (response) => {
//             // console.log("response", response)
//             // if (response.success) {
//             dispatch(applyDiscountAction(response)); // Pass the discount rates from response
//             resolve(response); // Resolve with the response data
//             // } else {
//             //     reject(response.error); // Reject with the error
//             // }
//         }, reject);
//     });
// };

export const validateAndApplyDiscount = (discountCode) => (dispatch) => {
    return new Promise((resolve, reject) => {
        api.checkDiscountCodeForAll(discountCode, (error, response) => {
            if (error) {
                reject(error);  // Reject the promise if there's an error
            } else if (response && Object.keys(response).length > 0) {
                dispatch(applyDiscountAction(response)); // Apply the discount
                resolve(response); // Resolve the promise with the response data
            } else {
                reject(new Error('Invalid discount code or empty response.')); // Reject with a generic error
            }
        });
    });
};


export const getDiscountCode = (discountCode) => () => {
    return new Promise((resolve, reject) => {
        api.getDiscountCode(discountCode, (response) => {
            console.log("response", response)
            // if (response.success) {
            // dispatch(applyDiscountAction(discountCode, response.discountRates)); // Pass the discount rates from response
            resolve(response); // Resolve with the response data
            // } else {
            //     reject(response.error); // Reject with the error
            // }
        }, reject);
    });
};




export const addToCart = productId => (dispatch, getState) => {
    const inventory = getState().products.byId[productId].inventory;
    const currentQuantityInCart = getState().cart.quantityById[productId] || 0;

    if (inventory > currentQuantityInCart && inventory > 0) {
        dispatch(addToCartUnsafe(productId));
        return true;
    } else {
        console.log("Insufficient inventory or product already in cart.");
        return false;
        // Optionally, you can dispatch an action to display a message to the user
    }
};

export const addToCartQuantity = (productId, quantity) => (dispatch, getState) => {
    const inventory = getState().products.byId[productId].inventory;
    const currentQuantityInCart = getState().cart.quantityById[productId] || 0;

    if (inventory >= currentQuantityInCart + quantity && inventory >= quantity) {
        dispatch(addToCartQuantityUnsafe(productId, quantity));
        return true;
    } else {
        console.log("Insufficient inventory or product already in cart.");
        return false;
        // Optionally, you can dispatch an action to display a message to the user
    }
}

export const removeFromCart = productId => (dispatch) => {
    dispatch(removeFromCartUnsafe(productId))
}

export const removeAllFromCart = productId => (dispatch) => {
    dispatch(removeAllFromCartUnsafe(productId))
}
//
// export const checkout = (products, form) => (dispatch) => {
//     console.log("In checkout action")
//     console.log(form)
// }

export const login = (form) => (dispatch) => {

    return new Promise((resolve, reject) => {
        // console.log("in login")
        api.login(form, (jwt) => {
            // console.log("in promise", jwt['jwt-token'])
            localStorage.setItem('jwtToken', jwt['jwt-token']); // Store the token in local storage
            localStorage.setItem('auth', "true"); // Store the token in local storage
            dispatch(loginAction(jwt['jwt-token']))
            resolve(jwt['jwt-token']); // Resolve with the order data
            // Replace the line above with the line below to rollback on failure:
            // dispatch({ type: types.CHECKOUT_FAILURE, cart })
        }, reject);
    });
};

export const logout = () => (dispatch) => {
    return new Promise((resolve) => {
        // Perform any necessary logout logic (e.g., clear session, invalidate token, etc.)
        // For example, you can clear the JWT token from local storage
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('auth');
        console.log("hello logout")


        // Dispatch the logout action
        dispatch(logoutAction());

        resolve(); // Resolve the promise
    });
};

export const checkout = (orderId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        api.buyProducts(orderId, (order) => {
            dispatch(createOrder(order))
            resolve(order); // Resolve with the order data
            // Replace the line above with the line below to rollback on failure:
            // dispatch({ type: types.CHECKOUT_FAILURE, cart })
        }, reject);
    });
};

export const confirmOrder = (orderId) => (dispatch) => {
    console.log("In checkout action")
    console.log(orderId)

    return new Promise((resolve, reject) => {
        api.confirmOrder(orderId, (msg) => {
            // Assuming 'msg' is the plain text response from the API
            // You can dispatch an action with the message if needed
            dispatch(confirmOrderAction(msg)); // Example: dispatch an action with the message
            // Resolve with the message
            resolve(msg);
        }, (error) => {
            // Reject with the error if the API call fails
            reject(error);
        });
    });
};

export const updateProduct = (editedProduct) => (dispatch) => {
    // console.log("In checkout action")
    // console.log(orderId)

    return new Promise((resolve, reject) => {

        api.updateProduct(editedProduct.productId, editedProduct, (product) => {
            // Assuming 'msg' is the plain text response from the API
            // You can dispatch an action with the message if needed
            dispatch(updateProductAction(product)); // Example: dispatch an action with the message
            // Resolve with the message
            resolve(product);
        }, (error) => {
            // Reject with the error if the API call fails
            reject(error);
        });
        api.getProducts(products => {
            dispatch(receiveProducts(products))
        })


        // api.updateProductImage(productId, imageFile, (product) => {
        //     // Assuming 'msg' is the plain text response from the API
        //     // You can dispatch an action with the message if needed
        //     dispatch(updateProductAction(product)); // Example: dispatch an action with the message
        //     // Resolve with the message
        //     resolve(product);
        // }, (error) => {
        //     // Reject with the error if the API call fails
        //     reject(error);
        // });
    });
};


export const cancelOrderUser = (orderId) => (dispatch) => {
    console.log("In checkout action before api")
    console.log(orderId)

    return new Promise((resolve, reject) => {
        api.cancelOrderUser(orderId, (msg) => {
            // Assuming 'msg' is the plain text response from the API
            // You can dispatch an action with the message if needed
            dispatch(cancelOrderAction(msg)); // Example: dispatch an action with the message
            // Resolve with the message
            resolve(msg);
        }, (error) => {
            // Reject with the error if the API call fails
            reject(error);
        });
    });
};

export const cancelOrderAdmin = (orderId) => (dispatch) => {
    console.log("In checkout action")
    console.log(orderId)

    return new Promise((resolve, reject) => {
        api.cancelOrderAdmin(orderId, (msg) => {
            // Assuming 'msg' is the plain text response from the API
            // You can dispatch an action with the message if needed
            dispatch(cancelOrderAction(msg)); // Example: dispatch an action with the message
            // Resolve with the message
            resolve(msg);
        }, (error) => {
            // Reject with the error if the API call fails
            reject(error);
        });
    });
};

export const deleteOrder = (orderId) => (dispatch) => {
    // console.log("In delete action")
    // console.log(orderId)

    return new Promise((resolve, reject) => {
        api.deleteOrder(orderId, (msg) => {
            // Assuming 'msg' is the plain text response from the API
            // You can dispatch an action with the message if needed
            dispatch(deleteOrderAction(msg)); // Example: dispatch an action with the message
            // Resolve with the message
            resolve(msg);
        }, (error) => {
            // Reject with the error if the API call fails
            reject(error);
        });
    });
};

export const orderSent = (orderId) => (dispatch) => {
    // console.log("In checkout action")
    // console.log(orderId)

    return new Promise((resolve, reject) => {
        api.orderSent(orderId, (order) => {
            // Assuming 'msg' is the plain text response from the API
            // You can dispatch an action with the message if needed
            dispatch(orderSentAction(order)); // Example: dispatch an action with the message
            // Resolve with the message
            resolve(order);
        }, (error) => {
            // Reject with the error if the API call fails
            reject(error);
        });
    });
};

export const orderPaid = (orderId) => (dispatch) => {
    // console.log("In checkout action")
    // console.log(orderId)

    return new Promise((resolve, reject) => {
        api.orderPaid(orderId, (order) => {
            // Assuming 'msg' is the plain text response from the API
            // You can dispatch an action with the message if needed
            dispatch(orderPaidAction(order)); // Example: dispatch an action with the message
            // Resolve with the message
            resolve(order);
        }, (error) => {
            // Reject with the error if the API call fails
            reject(error);
        });
    });
};

export const orderNotPaid = (orderId) => (dispatch) => {
    // console.log("In checkout action")
    // console.log(orderId)

    return new Promise((resolve, reject) => {
        api.orderNotPaid(orderId, (order) => {
            // Assuming 'msg' is the plain text response from the API
            // You can dispatch an action with the message if needed
            dispatch(orderNotPaidAction(order)); // Example: dispatch an action with the message
            // Resolve with the message
            resolve(order);
        }, (error) => {
            // Reject with the error if the API call fails
            reject(error);
        });
    });
};

export const orderNotSent = (orderId) => (dispatch) => {
    // console.log("In checkout action")
    // console.log(orderId)

    return new Promise((resolve, reject) => {
        api.orderNotSent(orderId, (order) => {
            // Assuming 'msg' is the plain text response from the API
            // You can dispatch an action with the message if needed
            dispatch(orderNotSentAction(order)); // Example: dispatch an action with the message
            // Resolve with the message
            resolve(order);
        }, (error) => {
            // Reject with the error if the API call fails
            reject(error);
        });
    });
};


// Action creator with authentication check
export const getAllOrders = () => {
    const isAuthenticated = localStorage.getItem("auth") === "true"
    console.log("hi")
    return (dispatch) => {
        if (isAuthenticated) {
            // If user is authenticated, fetch orders from API
            api.getOrders(orders => {
                dispatch(receiveOrders(orders));
                console.log("dispatching")
            });
        } else {
            // Handle unauthenticated user
            console.log("User is not authenticated. Unable to fetch orders.");
        }
    };
};
