import './App.css';
import Navbar from "./pages/Navbar";
import Kontakt from "./pages/Kontakt";

import Home from "./pages/Home";
import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Footer from "./pages/Footer";
import ProductsContainer from "./containers/ProductListContainer";
import SingleProductContainer from "./containers/SingleProductContainer";
import CartContainer from "./containers/CartContainer";
import Tretmani from "./pages/tretmani/Tretmani";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Microneedling from "./pages/info/Microneedling";
import PaymentDetails from "./pages/shop/cart/PaymentDetails";
import Login from "./pages/admin/Login";
import Orders from "./pages/admin/Orders";
import OrderPage from "./pages/admin/OrderPage";
import ErrorBoundary from "./error/ErrorBoundary";
import ScrollToTop from "./hooks/scrollToTop";
import WebshopUnavailable from "./pages/shop/cart/Nedostupno";



function App() {
    document.body.style.background = '#fef8f3';
    const isAuthenticated = localStorage.getItem("auth") === "true"



    return (
        <>
        <ErrorBoundary>
    <BrowserRouter>
        <ScrollToTop/>
                <Navbar isAuthenticated={isAuthenticated}/>
                <div style={{display: "flex",flexDirection: "column", height: "100%"}}>
                    <div style={{flexGrow: "1"}}>
                    <Routes>
                        <Route path='/' element={<Home/>} />
                        <Route path="shop/cart" element={<CartContainer/>} />
                        <Route path="shop/:productId" element={<SingleProductContainer/>}/>
                        <Route path="shop/" element={<ProductsContainer />} isAuthenticated={isAuthenticated}/>
                        {/*<Route path="orders/" element={<Orders />} isAuthenticated={isAuthenticated}/>*/}
                        <Route path='kontakt/' element={<Kontakt/>} />
                        {/*<Route path="cart/" element={<CartContainer/>} />*/}
                        <Route path="cart/" element={<WebshopUnavailable/>} />
                        <Route path="tretmani/microneedling/" element={<Microneedling/>} />
                        <Route path="tretmani/" element={<Tretmani/>} />
                        <Route path="narudzbe/:orderId" element={<OrderPage/>}/>
                        <Route path="narudzbe/" element={<Orders/>} />
                        <Route path="payment/" element={<PaymentDetails/>} />
                        <Route path="admin/login" element={<Login/>} />






                        {/*<Route path="shop/:productId" element={ProductPage} />*/}
                        {/*<Route path='shop/' element={<ShopKartice/>} />*/}

                        {/*<Route*/}
                        {/*    path="/"*/}
                        {/*    element={<Home />}*/}
                        {/*/>*/}

                        {/*<Route path='/shop/:productId' element={(routerProps) => <ShopKartice productId={routerProps.match.params.productId}/>}/>*/}


                        {/*<Route path="comments" component={() => (<Comments myProp="value" />)}/>*/}

                        {/*// <Route ProductPage path='/shop/:productId' component={(routerProps) => <ProductPage productId={routerProps.match.params.productId}/>}/>*/}
                    </Routes>
                    </div>
                <Footer/>
                </div>
            </BrowserRouter>
        </ErrorBoundary>
        </>
    );
}

export default App;

