import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";
import '../product/ProductPage.css'
import './ProductList.css'
import {useSelector} from "react-redux";

// kartica proizvoda

const ProductItem = ({ product, onAddToCartClicked }) => {
    const cartProducts = useSelector(state => state.cart.quantityById);
    const stateProducts = useSelector(state => state.products.byId);

    const [isHoveredAdd, setIsHoveredAdd] = useState(false);

    const divStyleAdd = {
        borderBottom: isHoveredAdd ? "1px dashed black" : "1px solid",
        borderRight: isHoveredAdd ? "1px dashed black" : "1px solid",
        borderLeft: isHoveredAdd ? "1px dashed black" : "none",
        borderTop: isHoveredAdd ? "1px dashed black" : "none",
        // transition: "border-color 0.3s ease",
        // padding: "3px",
        // margin: "5px",
        // animation: "moveDottedBorder 2s linear infinite",
    };

    const checkAvailable = (productId) => {
        return (!cartProducts[productId] && stateProducts[productId].inventory > 0) || stateProducts[productId].inventory > cartProducts[productId]
    }

    return (
        <div id="card" className="card background-white" style={{width: '18rem'}}>
            {/*<img className="card-img-top product-img" src="products/images/naslovna.jpg" alt="Card image cap"/>*/}
            <Link className='cards__item__link' to={"./" + product.productId}>
                <div className="figure" style={{position: 'relative'}}>
                    <img className="Sirv image-main .product-img card-img-top"
                         src={"/images/products/" + product.image.split(",")[0]} alt=""/>
                    {product.discount !== 0 && (
                        <div className="discount-label"> {/* Add a discount label */}
                            - {product.discount}%
                        </div>
                    )}
                    <img className="Sirv image-hover .product-img card-img-top"
                         src={"/images/products/" + product.image.split(",")[1]} alt=""/>
                </div>
            </Link>
            <div id="card-body" className="card-body background-white">
                <div>
                    <Link to={"./" + product.productId}>
                        <button
                            className="font-pro font-size-card margin-bottom-0 margin-top text-btn">{product.brandName}</button>
                    </Link>
                </div>
                <div className="flex-space-between background-white">
                    <div>
                        <Link to={"./" + product.productId}>
                            <button
                                className="font-pro font-size-card margin-bottom-0 margin-top text-btn">{product.productName}</button>
                        </Link>
                    </div>
                    <div>
                        {product.discount !== 0 &&
                            <button
                                className="font-pro font-size-card margin-bottom-0 padding-right text-btn">{product.specialPrice + "€"}</button>
                        }
                        {product.discount !== 0 &&
                            <button
                                className="font-pro font-size-card margin-bottom-0 text-btn strike-through-text dark-grey-text">{product.price + "€"}</button>
                        }
                        {product.discount === 0 &&
                            <button
                                className="font-pro font-size-card margin-bottom-0 text-btn">{product.price + "€"}</button>
                        }
                    </div>
                </div>
                <p className="card-text font-pro font-size-card font-colour-grey margin-bottom-0">{product.shortDescription}</p>
                {/*<button id="btn" type="button" className="font-tiskani-mali btn btn-primary margin-top"*/}
                {/*        onClick={onAddToCartClicked}>*/}
                {/*    Dodaj u košaricu*/}
                {/*</button>*/}
                {/*<div className="button-add">*/}
                    <button
                        id="btn"
                        type="button"
                        className={`font-pro font-size-card font-add-to-basket btn btn-primary margin-top ${checkAvailable(product.productId) ? "" : "sold-out"}`}
                        disabled={!checkAvailable(product.productId)}
                        onClick={onAddToCartClicked}
                        onMouseEnter={() => setIsHoveredAdd(true)}
                        onMouseLeave={() => setIsHoveredAdd(false)}
                        style={checkAvailable(product.productId) ? divStyleAdd : {}}
                    >
                        {checkAvailable(product.productId) ? "Dodaj u košaricu" : "Nema na zalihi"}
                    </button>
                {/*</div>*/}
            </div>
        </div>

        // <div style={{ marginBottom: 20 }}>
        //     <Product
        //         title={product.title}
        //         price={product.price}
        //         quantity={product.inventory} />
        //     <button
        //         onClick={onAddToCartClicked}
        //         disabled={product.inventory > 0 ? '' : 'disabled'}>
        //         {product.inventory > 0 ? 'Add to cart' : 'Sold Out'}
        //     </button>
        // </div>
    )
}

ProductItem.propTypes = {
    product: PropTypes.shape({
        productName: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        discount: PropTypes.number.isRequired,
        image: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        shortDescription: PropTypes.string.isRequired,
        // quantity: PropTypes.number.isRequired,
        specialPrice: PropTypes.number.isRequired

    }).isRequired,
    onAddToCartClicked: PropTypes.func.isRequired
}

export default ProductItem
